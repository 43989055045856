import WhatsappAsset from '@assets/images/eligibility/whatsapp.svg';
import { ButtonIcon } from '@components/Button';
import { EligibilityProvider } from '@contexts/eligibility';
import Logo from '@public/logo.svg';
import { LANGUAGES, OYEN_FAQ } from '@utils/constant';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Header from '@components/Header';
import SelectLanguage from './SelectLanguage';

type LayoutProps = {
  children: React.ReactNode;
};

function showContactSupportButton(path: string) {
  return path.endsWith('/plan') || path.includes('/eligibility');
}

function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [showSupport, setShowSupport] = useState(
    showContactSupportButton(router.asPath)
  );
  const [maintenanceBannerEnabled, setMaintenanceBannerEnabled] =
    useState<boolean>(false);

  const isTakaful = router.asPath.includes('/eligibility/takaful');

  useEffect(() => {
    setShowSupport(showContactSupportButton(router.asPath));
    setMaintenanceBannerEnabled(
      process.env.NEXT_PUBLIC_ENABLE_MAINTENANCE_BANNER === 'true'
    );
  }, [router.asPath]);

  const hideNav = router.asPath.startsWith('/claims/');

  const hideLang =
    router.asPath.startsWith('/eligibility/v3') ||
    router.asPath.startsWith('/eligibilityV2') ||
    router.asPath.startsWith('/add-pet') ||
    router.asPath.startsWith('/renewal');

  return (
    <main className="Layout">
      <header className="flex justify-between p-4 bg-white border-b items-center">
        <button
          onClick={() => {
            window.location.href = '/dashboard';
          }}
        >
          <Image src={Logo} height={24} width={80} alt="Oyen logo" />
        </button>
        <div className="flex justify-evenly">
          {!hideLang && <SelectLanguage languages={LANGUAGES} />}
          {!hideNav && (
            <ButtonIcon
              icon="profile"
              text={t('my-profile')}
              title={t('sign_in')}
              onClick={() => router.push('/dashboard/auth/sign_in')}
              size={1.125}
            />
          )}
          {!hideNav && (
            <ButtonIcon
              icon="question-mark"
              text={t('open')}
              title={t('faq_button_title')}
              onClick={() => {
                window.open(OYEN_FAQ.ROOT, '_blank');
              }}
              size={1.125}
            />
          )}
          {showSupport && (
            <button
              type="submit"
              className="text-xs font-semibold whitespace-nowrap py-1.5 px-1.5 rounded-full border-2 inline-flex items-center justify-center hover:text-secondary  hover:border-secondary focus:border-secondary focus:bg-secondary-50 disabled:bg-gray-100  disabled:text-gray-400  disabled:hover:border-gray-100  disabled:border-gray-100 disabled:cursor-not-allowed"
              onClick={() =>
                window.open(
                  isTakaful
                    ? 'https://api.whatsapp.com/send/?phone=60162996840&text=I+am+interested+in+Oyen+Takaful&type=phone_number&app_absent=0'
                    : 'https://api.whatsapp.com/send/?phone=60162996840&text=I+am+interested+in+Oyen&type=phone_number&app_absent=0'
                )
              }
            >
              <Image
                src={WhatsappAsset}
                height={18}
                width={18}
                alt="Whatsapp logo"
              />
              {t('open_chat')}
            </button>
          )}
        </div>
      </header>

      {/* Maintenance Banner */}
      {maintenanceBannerEnabled && (
        <Header
          title={
            <span
              className="text-red-950 font-medium text-sm"
              dangerouslySetInnerHTML={{
                __html: t('maintenance_banner') || 'Maintenance in progress',
              }}
            />
          }
          bgColor="bg-red-100"
          iconColor="text-red-500"
          roundedEdge="text-center"
          icon={' '}
        />
      )}

      <EligibilityProvider>{children}</EligibilityProvider>
      <footer className="LayoutFooter">
        <p>
          <small>
            &copy;
            {new Date().getFullYear()}
          </small>
        </p>
      </footer>
    </main>
  );
}

export default Layout;
